<template>
  <div
    ref="item"
    class="shared-kit-drop-item"
    :class="[props.disabled && 'shared-kit-drop-item--disabled']"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @touchend="handleTouchEnd"
  >
    <SharedKitDropItemHoverOverlay
      v-if="!noOverlay"
      :type="hoverType"
      :is-shown="isShowOverlay"
      @to-steam="handleSteam"
      @to-faq="handleFaq"
      @show-content="$emit('showPackContent')"
    />
    <div class="shared-kit-drop-item__background">
      <UiImage :src="props.image" :class="['background__image', { 'background__image--cs': isCs }]" :quality="80" />
      <div class="background__bg" :class="{ 'background__bg--cs': isCs }" />
    </div>
    <div v-if="props.shining" class="shared-kit-drop-item__shinner" />
    <div class="shared-kit-drop-item__top-left">
      <div v-if="showPrice" class="top-left__price">
        <BaseCurrency>{{ getValueRoundedByCurrency(price) }}</BaseCurrency>
      </div>
      <div v-if="type === dropItemTypes['3']" class="shared-kit-drop-item__steam-icon">
        <LazySvgoSquareSteamIcon />
      </div>
      <SetContentIcon v-if="dropsCount" :is-in-carousel="isInCarousel" :count="dropsCount" />
      <slot name="top-left" />
    </div>
    <div class="shared-kit-drop-item__top-right">
      <div v-if="isRightPrice" class="top-right__price">
        <BaseCurrency>{{ getValueRoundedByCurrency(price) }}</BaseCurrency>
      </div>
      <slot name="top-right" />
    </div>
    <div v-if="showName" class="shared-kit-drop-item__bottom">
      <SharedKitDropItemCsInformation
        v-if="shouldShowCsInfo && csInfo"
        :is-st="csInfo.isSt"
        :weapon-name="csInfo.weaponName!"
        :quality-short="csInfo.qualityShort"
        :quality-full="csInfo.qualityFull"
      />
      <div class="bottom__name">{{ props.name }}</div>
    </div>
    <div v-if="chance" class="shared-kit-drop-item__top-right shared-kit-drop-item__chance">
      <p class="shared-kit-drop-item__chance--value">{{ chance }}%</p>
      <p class="shared-kit-drop-item__chance--text">{{ $t('provablyFair.chance') }}</p>
    </div>
    <slot name="buttons" />
    <LazySvgoWaveIcon v-if="hasWaveOverlay" class="shared-kit-drop-item__wave-icon" />
  </div>
</template>

<script setup lang="ts">
import type {
  IPropsSharedKitDropItem,
  ISharedKitDropItemEmits,
} from '~/components/SharedKitDropItem/SharedKitDropItem.types';
import { createSteamMarketLink, SteamMarketIds } from '~/utils/steam.utils';
import { dropItemTypes } from '~/types/cases/dropItem';

const {
  project,
  faq: {
    type3: { categoryId, questionId },
  },
} = useProjectSettings();

const isCs = project.includes('cs');

const OPEN_OPTIONS = { target: '_blank' };

const props = withDefaults(defineProps<IPropsSharedKitDropItem>(), {
  showPrice: true,
  showName: true,
  hasWaveOverlay: false,
});

defineEmits<ISharedKitDropItemEmits>();

const shouldShowCsInfo = computed(() => {
  return isCs && type.value !== dropItemTypes[3];
});

const { id, name, type } = toRefs(props);

const item = ref<HTMLElement | null>(null);

const isHovered = ref(false);
const isMobileClicked = ref(false);

const isShowOverlay = computed(() => !props.noOverlay && isHovered.value);

const hoverType = computed(() => {
  switch (type.value) {
    case 3:
      return 'type3';
    case 20:
      return 'type20';
    default:
      return 'default';
  }
});

onMounted(() => {
  if (!item.value) return;
  useClickOutside(item.value, () => (isHovered.value = false), false);
});

const handleSteam = () => {
  if (needToSkipMobileClick()) return;

  return navigateTo(createSteamMarketLink(SteamMarketIds.DOTA, name.value, false), {
    external: true,
    open: OPEN_OPTIONS,
  });
};

const handleFaq = () => {
  if (needToSkipMobileClick()) return;

  const localeRoute = useLocaleRoute();
  const parsedRoute = localeRoute(ROUTING.FAQ.MAIN);

  if (!parsedRoute) return;

  return navigateTo(
    {
      path: parsedRoute.path,
      query: {
        category_id: categoryId,
        question_id: questionId,
      },
    },
    {
      open: OPEN_OPTIONS,
    },
  );
};

const handleTouchEnd = () => {
  if (isHovered.value || isMobileClicked.value) return;
  isHovered.value = true;
  isMobileClicked.value = true;
};

const needToSkipMobileClick = () => {
  if (isMobileClicked.value) {
    isMobileClicked.value = false;
    return true;
  }

  return false;
};

defineExpose({
  el: item,
  id: id?.value,
});
</script>

<style scoped lang="scss">
.shared-kit-drop-item {
  --main-color: v-bind('props.color');
  --shining-color: v-bind('props.shiningColor');
}
</style>

<style scoped lang="scss" src="./SharedKitDropItem.scss" />
